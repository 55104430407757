import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero/Hero'

const ns = `support-page`

const SupportPage = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={[
          'btn btn-link ',
          isCurrentEventKey ? ' opened ' : ' closed ',
        ]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    )
  }

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile:
              markdownRemark.frontmatter.hero.hero_image_mobile.publicURL,
            urlDesktop:
              markdownRemark.frontmatter.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <h1 className="page--title">{markdownRemark.frontmatter.title}</h1>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownRemark.html,
                }}
              />
              <a
                className={`${ns}__donation-link button`}
                href={markdownRemark.frontmatter.button_url}
                target="_blank"
                rel="noreferrer"
              >
                {markdownRemark.frontmatter.button_text}
              </a>
            </div>
            {markdownRemark.frontmatter.statement_section.show_section && (
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-10">
                    <h2 className="statment--text">
                      {
                        markdownRemark.frontmatter.statement_section
                          .statement_text
                      }
                    </h2>
                  </div>
                </div>
              </div>
            )}
            {markdownRemark.frontmatter.mebership_section.show_section && (
              <div className={`${ns}__sections top-large-spacing`}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {markdownRemark.frontmatter.mebership_section.message}
                </ReactMarkdown>
                <p className="top-medium-spacing">
                  {markdownRemark.frontmatter.mebership_section.select_message}
                </p>

                {markdownRemark.frontmatter.mebership_section.mebership_levels.map(
                  level => (
                    <Accordion>
                      <ContextAwareToggle eventKey="0">
                        <div className="accordian-title">
                          <p>
                            <span>{level.title}</span> {level.price}
                          </p>
                          <span className="text-right">
                            <span className="opened-icon">+</span>
                            <span className="closed-icon">—</span>
                          </span>
                        </div>
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey="0">
                        <div className="accordian-content">
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {level.details}
                          </ReactMarkdown>
                          <a
                            className={`${ns}__donation-link button`}
                            href={level.label_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {level.label}
                          </a>
                        </div>
                      </Accordion.Collapse>
                    </Accordion>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SupportPage

export const supportPageQuery = graphql`
  query SupportPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        button_url
        button_text
        title
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
        statement_section {
          show_section
          statement_text
        }
        mebership_section {
          message
          select_message
          show_section
          mebership_levels {
            details
            label
            label_url
            price
            title
          }
        }
      }
    }
  }
`
